body {
  margin: 0;
  font-family: FenwickWoodtype, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: FenwickWoodtype, monospace;
}

@font-face {
  font-family: 'FenwickWoodtype';
  src: local('FenwickWoodtype'), url(./fonts/FenwickWoodtype.ttf) format('truetype');
}